import Vue from 'vue'

export function hasPermission(parms) {
  if (process.env.NODE_ENV === 'development') return true
  const str = String(parms)
  const authoritys = JSON.parse(window.sessionStorage.getItem('btnPermissions')) || []
  return !!authoritys.find((e) => e.nodeCode === str)
}

Vue.directive('hasBtn', {
  inserted: (el, binding, vnode) => {
    if (!hasPermission(binding.value)) {
      el.remove()
    }
  },
})
