import { getSts } from '@/api/common.js'

const getDefaultState = () => {
  return {
    client: {},
  }
}

const state = getDefaultState()

const mutations = {
  SET_CLIENT: (state, client) => {
    state.client = client
  },
}

const actions = {
  getClient({ commit }) {
    return new Promise((resolve, reject) => {
      getSts()
        .then((response) => {
          // console.log(response);
          commit('SET_CLIENT', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
