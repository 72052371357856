import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/orderMgt/orderList/index'),
    meta: {
      title: '订单管理',
    },
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/orderMgt/orderList/orderDetail'),
    meta: {
      title: '订单详情',
    },
  },
  {
    path: '/aditionalPurchaseInfoDetail',
    component: () => import('@/views/orderMgt/orderList/aditionalPurchaseInfoDetail'),
    meta: {
      title: '加购产品详情',
    },
  },
  {
    path: '/afterSaleDetails',
    name: 'afterSaleDetails',
    component: () => import('@/views/orderMgt/orderList/afterSaleDetails'),
    meta: {
      title: '售后详情',
    },
  },
  {
    path: '/replySupplyEvaluationList',
    component: () => import('@/views/orderMgt/orderList/replySupplyEvaluationList'),
    meta: {
      title: '评价回复',
    },
  },
  {
    path: '/successEnterServer',
    component: () => import('@/views/orderMgt/orderList/successEnterServer'),
    meta: {
      title: '完成确认服务',
    },
  },
  {
    path: '/serviceManagement/subOrderDetail',
    name: 'subOrderDetail',
    component: () => import('@/views/serviceManagement/detail'),
    meta: {
      title: '子服务单详情',
    },
  },
  {
    path: '/serviceManagement/makeUp',
    component: () => import('@/views/serviceManagement/makeUp'),
    meta: {
      title: '化妆服务',
    },
  },
  {
    path: '/serviceManagement/shoot',
    component: () => import('@/views/serviceManagement/shoot'),
    meta: {
      title: '摄影服务',
    },
  },
  {
    path: '/serviceManagement/pose',
    component: () => import('@/views/serviceManagement/pose'),
    meta: {
      title: '美姿服务',
    },
  },
  {
    path: '/serviceManagement/cameraShooting',
    component: () => import('@/views/serviceManagement/cameraShooting'),
    meta: {
      title: '摄像服务',
    },
  },
  {
    path: '/serviceManagement/modelling',
    component: () => import('@/views/serviceManagement/modelling'),
    meta: {
      title: '造型服务',
    },
  },
  {
    path: '/serviceManagement/finedRepair',
    component: () => import('@/views/serviceManagement/finedRepair'),
    meta: {
      title: '精修服务',
    },
  },
  {
    path: '/serviceManagement/clip',
    component: () => import('@/views/serviceManagement/clip'),
    meta: {
      title: '剪辑服务',
    },
  },
  {
    path: '/serviceManagement/design',
    component: () => import('@/views/serviceManagement/design'),
    meta: {
      title: '设计服务',
    },
  },
  {
    path: '/serviceManagement/firstRepair',
    component: () => import('@/views/serviceManagement/firstRepair'),
    meta: {
      title: '初修服务',
    },
  },
  {
    path: '/serviceManagement/make',
    component: () => import('@/views/serviceManagement/make'),
    meta: {
      title: '制作服务',
    },
  },
  {
    path: '/personnelManagement',
    component: () => import('@/views/systemSettings/personnelManagement'),
    meta: {
      title: '人员管理',
    },
  },
  {
    path: '/roleManagement',
    component: () => import('@/views/systemSettings/roleManagement'),
    meta: {
      title: '角色权限',
    },
  },
  {
    path: '/shedSpace/supplyShedSpaceType',
    name: 'supplyShedSpaceType',
    component: () => import('@/views/shedSpace/shedSpaceType/index.vue'),
    meta: {
      title: '棚位类型列表',
    },
  },
  {
    path: '/shedSpace/supplyShedSpaceList',
    name: 'supplyShedSpaceList',
    component: () => import('@/views/shedSpace/shedSpaceList/index.vue'),
    meta: {
      title: '棚位管理列表',
    },
  },
  {
    path: '/shedSpace/supplyShedSpaceDetail',
    name: 'supplyShedSpaceDetail',
    component: () => import('@/views/shedSpace/shedSpaceDetail/index.vue'),
    meta: {
      title: '棚位预约详情',
    },
  },
  {
    path: '/addPayOrder',
    name: 'addPayOrder',
    component: () => import('@/views/add-pay-order/index.vue'),
    meta: {
      title: '新增收款订单',
    },
  },
  {
    path: '/questionnaireDetail',
    name: 'questionnaireDetail',
    component: () => import('@/views/orderMgt/orderList/questionnaireDetail/index.vue'),
    meta: {
      title: '问卷详情',
    },
  },
  {
    path: '/lookSmaple',
    name: 'LookSmaple',
    component: () => import('@/views/look-smaple/index.vue'),
    meta: {
      title: '线下看样',
    },
  },
  {
    path: '/shootRegister',
    name: 'shootRegister',
    component: () => import('@/views/shootRegister/index.vue'),
    meta: {
      title: '主题登记',
    },
  },
  {
    path: '/productManagement',
    name: 'productManagement',
    component: () => import('@/views/product-management/index.vue'),
    meta: {
      title: '产品管理',
    },
  },
  {
    path: '/productDifferencePage', // 查看置换对比
    name: 'productDifferencePage',
    component: () => import('@/views/productDifferencePage/index.vue'),
    meta: {
      title: '产品置换对比',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  // 为微应用路由加上前缀，如果不加微应用路由切换会有问题
  // eslint-disable-next-line no-underscore-dangle
  base: window.__POWERED_BY_QIANKUN__ ? '/index/supplyorder' : process.env.BASE_URL,
  routes,
})
export default router
