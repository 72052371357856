import Vue from 'vue'
import 'normalize.css/normalize.css'
import '../theme/index.css'
import ElementUI, { Tooltip } from 'element-ui'
import Pages from '@/components/pages'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss'
import './public-path'
import { downloadPhoto } from '@/utils/downloadPhoto'

import 'xe-utils'
import '@/utils/buttonAuthority.js'

import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

import CommonFn from '@/utils/common.js'
import { addCssByLink } from './utils/icon-link.js'
import businesscomponent from 'business-component-next'
import 'business-component-next/lib/style.css'

Vue.use(VXETable)

Vue.use(ElementUI, {
  size: 'small',
  zIndex: 3000,
})
Vue.prototype.$commonFn = new CommonFn()
addCssByLink('//at.alicdn.com/t/c/font_3337821_1pzwfb89we4.css')

Vue.use(businesscomponent, {
  url: process.env.VUE_APP_BASE_API,
})

Vue.use(Tooltip)
Vue.component('Pages', Pages)

Vue.config.productionTip = false
Vue.prototype.$downloadPhoto = downloadPhoto
let instance = null

function render(props = {}) {
  // container 是 qiankun 包装过的微应用容器
  // eslint-disable-next-line no-unused-vars
  const {
    container,
    onGlobalStateChange,
    setGlobalState,
    // customProps
  } = props
  // console.log("自定义的props参数", customProps);
  Vue.prototype.$actions = {
    onGlobalStateChange,
    setGlobalState,
  }
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app')
}

// 独立运行时
// eslint-disable-next-line no-underscore-dangle
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export async function bootstrap() {
  console.log('[vue] vue app bootstraped')
}
export async function mount(props) {
  console.log('[vue] props from main framework')
  render(props)
}
export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
}
