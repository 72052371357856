import JSZip from './jszip.min.js'
import { Notification } from 'element-ui'
import { saveAs } from 'file-saver'
import axios from 'axios'
import moment from 'moment'

const getFile = async (url) => {
  return await new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: 'arraybuffer',
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        // console.log(err);
        reject(err)
      })
  })
}

export const downloadPhoto = async function (data) {
  // let list = [{
  //     name: ""//名字不能相同,
  //     url: ""
  // }];
  let num = 0

  const zip = new JSZip()

  const promises = data.map((el) => {
    // 生成promise实例
    return getFile(el.url)
      .then((res) => {
        zip.file(el.name + el.suffix, res)
      })
      .catch(() => {
        num++
      })
  })

  // 异步处理
  await Promise.all(promises.map((p) => p.catch(() => ''))).then(async () => {
    // 生成二进制流
    await zip
      .generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: {
          level: 9,
        },
      })
      .then(async (content) => {
        await saveAs(content, moment().format('YYYY-MM-DD'))
        /* 保存文件 */
        if (num > 0)
          Notification.error({
            title: '失败',
            message: num + '张图片下载失败',
          })
      })
      .catch((err) => {
        console.log(err)
      })
  })
}
