const CommonFn = class {
  // 近X天
  getDateInterval(e) {
    const timeList = []
    const now = new Date()

    const splicingZero = function (num) {
      if (num < 10) {
        return `0${num}`
      } else {
        return num
      }
    }

    const currentYear = now.getFullYear()
    const currentMonth = splicingZero(now.getMonth() + 1)
    const currentDay = splicingZero(now.getDate())

    let start = null

    // 近7天
    if (e === 'lastWeekDay') {
      start = new Date(now.getTime() - 60 * 60 * 24 * 6000)
    }

    // 近30天
    if (e === 'lastMonthDay') {
      start = new Date(now.getTime() - 60 * 60 * 24 * 29000)
    }

    // 近365天
    if (e === 'lastYearDay') {
      start = new Date(now.getTime() - 60 * 60 * 24 * 364000)
    }

    timeList[0] = start.getFullYear() + '-' + splicingZero(start.getMonth() + 1) + '-' + splicingZero(start.getDate()) + ' 00:00:00'
    timeList[1] = currentYear + '-' + currentMonth + '-' + currentDay + ' 23:59:59'

    return timeList
  }
}
export default CommonFn
