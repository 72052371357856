<template>
  <el-pagination
    :small="small"
    background
    :page-sizes="[10, 20, 30, 40]"
    :current-page="currentPage"
    layout="total, prev, pager, next,sizes"
    :total="total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  ></el-pagination>
</template>

<script>
export default {
  name: 'Pages',
  props: {
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    small: {
      type: String,
      default: 'small',
    },
  },
  data() {
    return {}
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('handleSizeChange', val)
    },
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val)
    },
  },
}
</script>

<style lang="less" scoped></style>
