import axios from 'axios'
import { Notification } from 'element-ui'
import { getToken, setToken } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'https://gateway.zzgy333.com/',
  timeout: 15000, // request timeout
})

service.interceptors.request.use(
  (config) => {
    let token = getToken()
    if (process.env.NODE_ENV === 'development') {
      token =
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgKCJCQyMGWzBhsTtbLPIkbupvAtz1WOG9he6aR+aLCQgXNGu4cpenwIeCZTfU38OC0383bo22ddh/pL0+Dy7od4UD9lGXXvNj1hTmgc0t/gdO1cB+ssdfFGc3K9lEV7bdXuNJbmy2B7x7TkQBLB3xRBYq6xxosXzDHZQCsnqCxui6aL3Qxo1BSXflRENQAR7csFoEao8if/c5dkEQ9xJixomRxPOCAq2ZrR+rbYh/T37yHk6ffqSEr2K/xcKUQw2IUacdzbEeNByoy5iFEv4Tdzus49u4K4r0b8asWmelBVOYq6/mEUo5RziBn9ajKib1w==.f1cde5257556b28528c09d2c6750964f601c49b8'
      // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgC4GLt2LGjndqpPn17csQRkRRDfwR7pdZXWoRhoKELwbJXW4B4BmHw4ic9rUPEHqXOCpPGjPb48kq1CQ6VItYjpDdiWxxKMthOWrwvx3eOwCO1cB+ssdfFGc3K9lEV7bdXuNJbmy2B7x7TkQBLB3xRCu8yO09hMHw6EkSDyhhELR4D6HcTToKE1AimqTeZGIjhILeLmwXdQxf/If9qbVZS8mRxPOCAq2ZrR+rbYh/T37k3wokfnIWOqzOI2LY8nz5ZDuMgzsj+j7upj7oaZQF6sKk/DI5sFxIlGsGERsfX1vItw8UacVQi8eEwQTxn6QMA==.9d979611c357e6392c296e4b0a655d49db458222';
      // token =
      //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgHuUb2/PN/YY/eTMnmMx5BDlm7I/xhM+aTI47mVbFxF1g/AoUaxXzitM4QA1LwuX82yr4pIGeTHOw9gTdLeusezQYnl52woQypKUxtDaNv41O1cB+ssdfFGc3K9lEV7bdXuNJbmy2B7x7TkQBLB3xRBUXFiHB1YQbq64FYNVGvInpRVfnEW6kr+I/4EUoNirv4V0KLKeFw0mxWA9FcjsUcEmRxPOCAq2ZrR+rbYh/T37ElepMYjWaEz88hWldKf/DXcPtKGe7+2p+6vIeuzSbNCGq9FN2FHtHXW/XHqxVByQcuafyTS4T/FqCDnHqN09ew==.9596b920e57f4a9e7d0ddf9ca80b89ba8fe56e0f';
      // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgHuUb2/PN/YY/eTMnmMx5BBWbkcGNaGOwDgGVWt/iHWoNRMJ0hqLQdJfckXZLRWNgGyr4pIGeTHOw9gTdLeusezQYnl52woQypKUxtDaNv41O1cB+ssdfFGc3K9lEV7bdXuNJbmy2B7x7TkQBLB3xRD9yzvgkf0HFtVYcHj1wUeBOpCdLZZe6uxP3kqpZu0VPxAV/PtJiPjkZolT6jyKViEmRxPOCAq2ZrR+rbYh/T37ElepMYjWaEz88hWldKf/DXcPtKGe7+2p+6vIeuzSbNCGq9FN2FHtHXW/XHqxVByQcuafyTS4T/FqCDnHqN09ew==.f78cb3b6a70540ef217a548ea5a030a37f60e486';
      // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgDSmYim1spfxGN1bLCtLlMgopgEOj7Syyc9TrXb+w+y38KZeuPsWR02wwiv9EBEmIWyr4pIGeTHOw9gTdLeusezQYnl52woQypKUxtDaNv41O1cB+ssdfFGc3K9lEV7bdXuNJbmy2B7x7TkQBLB3xRBLvvYljhAWYbnKlLt8f/VBBvJBSh4q1g/Hxhkc9QU6ygr+paYh91ZcFwDFL2mU1pgmRxPOCAq2ZrR+rbYh/T37cE54lMglcs+x5E+7gCE1oWRp+A8qLCEm/Zf2CxxQlDac1JISdQZTk/Y7U/2qDJILH1E1irB7L0p74YSZBGgFzg==.c0102273bb9050f94831aeaa338f1416275930a4';
      // token =
      //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgDSmYim1spfxGN1bLCtLlMjhJtw34b7txcOPYG0BJPRAGkNx4D1vumArVicroqSJRWyr4pIGeTHOw9gTdLeusezQYnl52woQypKUxtDaNv41O1cB+ssdfFGc3K9lEV7bdXuNJbmy2B7x7TkQBLB3xRDibbKHxHrWmEO72sNTZsuIbIB5Qg9SbPkx6RLOkdDQ6GYd/ad187KwNQOeuwh7fSomRxPOCAq2ZrR+rbYh/T37P/tNB/C+dlvSDD+U5QXee1IRMdd6EFd1fdCzLXC9lvw3AsrWXD6CM4381JDKNtj2kO4yDOyP6Pu6mPuhplAXqwqT8MjmwXEiUawYRGx9fW/8qWqKInbVZlR0hNY+WshT.71f9ad21d4d0725009c6e01eca51ca3bd4040a24';
      setToken(token)
    }
    if (token) {
      config.headers.accessToken = token
    }
    // config.headers['appId'] = 'tongyuan-vue-supply-order-web';
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

service.interceptors.response.use(
  (response) => {
    const data = response.data
    if (response.config.responseType === 'blob') {
      // eslint-disable-next-line consistent-return
      return response.data
    }
    if (data.success) {
      return data
    } else if (data.code === '4404' || data.code === '4405' || data.code === '4401') {
      Notification.error({
        title: '错误',
        message: data.description,
      })
      setTimeout(() => {
        location.reload()
      }, 1500)
    } else if (data.code === '401') {
      Notification.error({
        title: '错误',
        message: data.description,
      })
      setTimeout(() => {
        window.location = data.redirectUrl
      }, 500)
      return Promise.reject(data.message)
    } else {
      if (data.code !== '2') {
        Notification.error({
          title: '错误',
          message: data.description,
        })
      }
      return Promise.reject(data)
    }
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default service
