import request from '@/utils/request'

export function getDictionaryList(data) {
  return request({
    url: 'misc-web-api/admin/dictionary/queryChildsById',
    method: 'POST',
    data,
  })
}
export function getUserInfo(data) {
  return request({
    url: 'user-web-api/supplier/user/info/supplier/findLoginInfo',
    method: 'POST',
    data,
  })
}

export function upload(data) {
  return request({
    url: '/misc-web-api/common/file/uploadImage',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    timeout: 600000,
  })
}

//  获取OSS
export function getSts(data) {
  return request({
    url: 'misc-web-api/common/file/getSts',
    method: 'get',
    data,
  })
}
